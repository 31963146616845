<template>
  <div class="wrapper">
    <div class="footer-holder">
      <div class="left-slogan">
        <VueText sizeLevel="4" weightLevel="5" color="grey-40"> {{ leftText }}</VueText>
      </div>
      <div class="middle-text-and-links-holder">
        <VueText sizeLevel="3" weightLevel="1" color="grey-30" class="middle-text">
          {{ middleText }}
        </VueText>
        <div class="link">
          <VueText
            v-for="item in staticPages"
            :key="item.id"
            sizeLevel="1"
            weightLevel="3"
            color="grey-30"
            class="link-item"
            @click="showStaticModal(item)"
          >
            {{ item.title }}
          </VueText>
          <!-- <VueText sizeLevel="1" weightLevel="3" color="grey-30" class="link-item">
            Site Haritası
          </VueText> -->
        </div>
      </div>
      <div class="copyright">
        <VueText sizeLevel="3" weightLevel="1" color="grey-40"> {{ copyright }}</VueText>
      </div>
    </div>
    <BrandInfoPopup :show.sync="staticModal.show">
      <div class="static-content-modal">
        <h2>{{ staticModal.data.title }}</h2>
        <div class="modal-content">
          <div class="content-text" v-html="staticModal.data.desc"></div>
        </div>
      </div>
    </BrandInfoPopup>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'VueAuthLandingFooter',
  components: {
    VueText,
    BrandInfoPopup,
  },
  data() {
    return {
      leftText: 'SİGARA İÇMEK ÖLDÜRÜR!',
      middleText:
        'PMAktif portal, tacir/esnaf niteliği taşıyan satış noktalarına yönelik olarak hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile iletişime geçiniz.',
      copyright: 'Copyright © 2020 PHILIP MORRIS',
      staticPages: [
        {
          id: 0,
          title: 'Yasal Şartlar',
          type: 'YasalUyari',
          desc: null,
        },
        {
          id: 1,
          title: 'Gizlilik Bildirimi',
          type: 'VeriPolitikalari',
          desc: null,
        },
      ],
      staticModal: { show: false, data: '' },
    };
  },
  methods: {
    showStaticModal(item) {
      StaticContent.getPublicStaticContent(item.type).then(res => {
        if (res.data.Data) {
          this.staticModal = {
            show: true,
            data: {
              title: item.title,
              desc: res.data.Data.content,
            },
          };
        }
      });
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  width: 100%;
  background-color: palette-color-level('white', '100');

  .left-slogan {
    border: 2px solid palette-color-level('grey', '50');
    padding: palette-space-level('20') palette-space-level('80');
  }

  .footer-holder {
    display: flex;
    align-items: center;

    .middle-text-and-links-holder {
      margin-left: palette-space-level('20');
      max-width: 350px;

      .link,
      .link-item,
      .middle-text {
        display: inline;
      }

      .link {
        padding: 0 palette-space-level(20);

        .link-item {
          margin-right: palette-space-level('10');
          border-bottom: 1px solid palette-color-level('grey', '40');
          cursor: pointer;
        }
      }
    }
  }
}
.static-content-modal {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: palette-color-level('grey', '70');
    border-radius: palette-radius-level(1);
  }

  ::-webkit-scrollbar-thumb {
    background-color: palette-color-level('grey', '80');
    border-radius: palette-radius-level(5);
  }
  max-width: 510px;
  h2 {
    text-align: left;
    font-size: palette-font-size-level('8');
    font-weight: palette-font-weight-level(2);
    color: palette-color-level('grey', '40');
    margin-bottom: palette-space-level('20');
  }
  .modal-content {
    max-height: 590px;
    overflow-y: auto;
    padding-right: palette-space-level('30');
    .content-text {
      font-size: palette-font-size-level('4');
      font-weight: palette-font-weight-level(1);
      line-height: 24px;
      color: palette-color-level('grey', '40');
      div {
        margin: 0;
        padding: 0 !important;
      }
    }
  }
}
</style>
