<template>
  <div class="layout-default">
    <VueAuthHeader class="header"></VueAuthHeader>
    <div class="main">
      <img src="@/assets/images/background/auth-background.jpg" alt="pm-aktif-bg" class="main-bg" />
      <div class="auth-form-holder">
        <slot class="main-slot" />
      </div>
      <BrandModal />
    </div>
    <div class="content-layout-fixer">
      <div class="ad-wrapper-large">
        <div id="div-gpt-ad-1587046762079-0"></div>
      </div>
    </div>
    <VueAuthLandingFooter class="footer"></VueAuthLandingFooter>
  </div>
</template>

<script>
import VueAuthHeader from '@/components/brand/VueAuthHeader/VueAuthHeader.vue';
import VueAuthLandingFooter from '@/components/brand/VueAuthLandingFooter/VueAuthLandingFooter.vue';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import gtmUtils from '@/mixins/gtmUtils.js';
export default {
  name: 'LayoutAuthForm',
  components: { VueAuthHeader, VueAuthLandingFooter, BrandModal },
  mixins: [gtmUtils],
  mounted() {
    //initialize google ads
    this.pushAds('large');
  },
};
</script>

<style lang="scss" scoped>
.layout-default {
  min-width: $body-width;
  .header {
    padding: palette-space-level('30') palette-space-level('30') palette-space-level('80')
      palette-space-level('20');
  }
  .footer {
    padding: palette-space-level('20') palette-space-level('30');
  }
}
.main {
  width: 100%;
  flex: 1;
  position: relative;
  .main-bg {
    width: 100%;
  }

  .auth-form-holder {
    border-top: 10px solid palette-color-level('grey', '10');
    max-width: 491px;
    min-width: 491px;
    min-height: 700px;
    background-color: palette-color-level('white', '100');
    position: absolute;
    left: 40%;
    top: -102px;
    z-index: 2;
    padding: 0 palette-space-level(30);
  }
}
</style>
