<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <div class="logo-wrapper">
        <a href="/">
          <img src="@/assets/logos/pm-aktif-logo.svg" alt="pm-aktif-logo" class="logo" />
        </a>
      </div>
      <div class="select-login-type-text">
        <VueText
          v-html="showTypeText ? selectPageText : '&nbsp'"
          sizeLevel="5"
          weightLevel="1"
          color="grey-40"
        >
        </VueText>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';

export default {
  name: 'VueAuthHeader',
  components: { VueText },
  data() {
    return {
      selectPageText: 'Giriş yapmak için yandaki yöntemlerden birini seçiniz.',
      loginPath: `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
      showTypeText: true,
    };
  },
  mounted() {
    this.$router.currentRoute.path === this.loginPath
      ? (this.showTypeText = true)
      : (this.showTypeText = false);
  },
  watch: {
    $route(to) {
      to.path === this.loginPath ? (this.showTypeText = true) : (this.showTypeText = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding: palette-space-level('20');
  .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .select-login-type-text {
      line-height: 1.78;
    }
  }

  .logo-wrapper {
    margin-bottom: palette-space-level('10');
  }
}
</style>
