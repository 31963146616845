<template>
  <LayoutAuthForm>
    <router-view></router-view>
  </LayoutAuthForm>
</template>
<script>
import LayoutAuthForm from '@/views/layouts/LayoutAuthForm.vue';
import { clearAppData } from '@/mixins/storageUtils.js';

export default {
  name: 'Login',
  components: { LayoutAuthForm },
  beforeCreate() {
    clearAppData('login');
  },
};
</script>
